<template>
  <div>
    <div>
      <b-form-group
        label="Nombre:"
        label-for="name-input"
        label-cols="0"
        label-cols-sm="3"
        class="m-0 p-0 mb-2"
      >
        <b-form-input
          id="name-input"
          name="name-input"
          v-model="$v.campus.name.$model"
          :state="validateState('name')"
          aria-describedby="input-name-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback id="input-name-live-feedback"
          >Este campo es obligatorio y debe tener al menos 3
          caracteres.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label="Teléfono:"
        label-for="phone-input"
        label-cols="0"
        label-cols-sm="3"
        class="m-0 p-0 mb-2"
      >
        <b-form-input
          id="phone-input"
          name="phone-input"
          type="tel"
          v-model="$v.campus.phone.$model"
          :state="validateState('phone')"
          aria-describedby="input-phone-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback id="input-phone-live-feedback"
          >Este campo es obligatorio y debe tener al menos 3
          caracteres.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        :label="`${$getVisibleNames('common.address', false, 'Dirección')}:`"
        label-for="input-line_1"
        label-cols="0"
        label-cols-sm="3"
        class="m-0 p-0 mb-2"
      >
        <b-form-input
          id="input-line_1"
          name="input-line_1"
          v-model="$v.address.line_1.$model"
          :state="validateAddress('line_1')"
          aria-describedby="input-line_1-live-feedback"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback id="input-line_1-live-feedback"
          >Este campo debe tener al menos 3 caracteres.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        :label="`${$getVisibleNames(
          'common.address',
          false,
          'Dirección'
        )} 2 (Opcional):`"
        label-for="input-line_2"
        label-cols="0"
        label-cols-sm="3"
        class="m-0 p-0"
      >
        <b-form-input
          id="input-line_2"
          name="input-line_2"
          v-model="$v.address.line_2.$model"
          :state="validateAddress('line_2')"
          aria-describedby="input-line_2-live-feedback"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback id="input-line_2-live-feedback"
          >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label="Código postal:"
        label-for="input-zip_code"
        label-cols="0"
        label-cols-sm="3"
        class="m-0 p-0"
      >
        <b-form-input
          id="input-zip_code"
          name="input-zip_code"
          v-model="$v.address.zip_code.$model"
          :state="validateAddress('zip_code')"
          aria-describedby="input-zip_code-live-feedback"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback id="input-zip_code-live-feedback"
          >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        v-if="countries.length > 1"
        :label="`${$getVisibleNames('common.country', false, 'País')}:`"
        label-for="select-country"
        label-cols="0"
        label-cols-sm="3"
        class="m-0 p-0"
      >
        <v-select
          id="select-country"
          :options="countries"
          v-model="$v.selected_country.$model"
          @input="
            (value) => {
              selected_region = null;
              address.commune = null;
            }
          "
          :reduce="(countries) => countries.id"
          :state="validateCountry()"
          :placeholder="`${
            countries.length > 0
              ? String(
                  'Seleccione una ' +
                    $getVisibleNames(
                      'common.country',
                      false,
                      'País'
                    ).toLowerCase()
                )
              : String(
                  'No cuenta con ' +
                    $getVisibleNames(
                      'common.country',
                      true,
                      'Países'
                    ).toLowerCase() +
                    ' creados'
                )
          }`"
          label="name"
          track-by="id"
          size="sm"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontró resultados para <em>{{ search }}</em
              >.
            </template>
          </template>
        </v-select>
        <div
          class="div-invalid-feedback"
          v-if="$v.selected_country.$anyError == true"
        >
          Debe seleccionar
          {{ $getVisibleNames("common.country", false, "País") }}.
        </div>
      </b-form-group>
      <b-form-group
        v-if="selected_country || countries.length == 1"
        :label="`${$getVisibleNames('common.region', false, 'Región')}:`"
        label-for="select-region"
        label-cols="0"
        label-cols-sm="3"
        class="m-0 p-0"
      >
        <v-select
          id="select-region"
          :options="filterRegions"
          v-model="$v.selected_region.$model"
          :reduce="(filterRegions) => filterRegions.id"
          :state="validateRegion()"
          @input="() => (address.commune = null)"
          :placeholder="`${
            filterRegions.length > 0
              ? String(
                  'Seleccione una ' +
                    $getVisibleNames(
                      'common.region',
                      false,
                      'Región'
                    ).toLowerCase()
                )
              : String(
                  'No cuenta con ' +
                    $getVisibleNames(
                      'common.region',
                      true,
                      'Regiones'
                    ).toLowerCase() +
                    ' creadas'
                )
          }`"
          label="name"
          track-by="id"
          size="sm"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontró resultados para <em>{{ search }}</em
              >.
            </template>
          </template>
        </v-select>
        <div
          class="div-invalid-feedback"
          v-if="$v.selected_region.$anyError == true"
        >
          Debe seleccionar
          {{ $getVisibleNames("common.region", false, "Región") }}.
        </div>
        <!-- <b-form-select
          style="display: none"
          id="select-region"
          name="select-region"
          :options="regions"
          value-field="id"
          text-field="name"
          v-model="$v.selected_region.$model"
          :state="validateRegion()"
          aria-describedby="select-region-live-feedback"
          size="md"
          rows="3"
          max-rows="8"
          @change="() => (address.commune = null)"
        >
          <template #first>
            <b-form-select-option :value="null">
              -- Seleccione
              {{ $getVisibleNames("common.region", false, "Región") }} --
            </b-form-select-option>
          </template>
        </b-form-select>
        <b-form-invalid-feedback id="select-region-live-feedback"
          >Debe seleccionar
          {{
            $getVisibleNames("common.region", false, "Región")
          }}.</b-form-invalid-feedback
        >
      -->
      </b-form-group>
      <b-form-group
        v-if="selected_region"
        :label="`${$getVisibleNames('common.commune', false, 'Comuna')}:`"
        label-for="select-commune"
        label-cols="0"
        label-cols-sm="3"
        class="m-0 p-0"
      >
        <v-select
          id="select-commune"
          :options="filterCommunes"
          v-model="$v.address.commune.$model"
          :reduce="(filterCommunes) => filterCommunes.id"
          :dropdown-should-open="dropdownShouldOpen"
          :placeholder="`${
            filterCommunes.length > 0
              ? String(
                  'Seleccione una ' +
                    $getVisibleNames(
                      'common.commune',
                      false,
                      'Comuna'
                    ).toLowerCase()
                )
              : String(
                  'No cuenta con ' +
                    $getVisibleNames(
                      'common.commune',
                      true,
                      'Comunas'
                    ).toLowerCase() +
                    ' creadas'
                )
          }`"
          label="name"
          track-by="id"
          size="sm"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontró resultados para <em>{{ search }}</em
              >.
            </template>
          </template>
        </v-select>
        <div
          class="div-invalid-feedback"
          v-if="$v.address.commune.$anyError == true"
        >
          Debe seleccionar
          {{ $getVisibleNames("common.commune", false, "Comuna") }}
        </div>
        <!-- <b-form-select
          id="select-commune"
          style="display: none"
          name="select-commune"
          :options="filterCommunes"
          value-field="id"
          text-field="name"
          v-model="$v.address.commune.$model"
          :state="validateAddress('commune')"
          aria-describedby="select-commune-live-feedback"
          size="md"
          rows="3"
          max-rows="8"
        >
          <template #first>
            <b-form-select-option :value="null">
              -- Seleccione
              {{ $getVisibleNames("common.commune", false, "Comuna") }} --
            </b-form-select-option>
          </template>
        </b-form-select>
        <b-form-invalid-feedback id="select-commune-live-feedback"
          >Debe seleccionar
          {{
            $getVisibleNames("common.commune", false, "Comuna")
          }}.</b-form-invalid-feedback
        >
      -->
      </b-form-group>
      <b-form-group
        v-if="!isNaN(this.campus.id)"
        label-for="input-modification-date"
        label-cols="0"
        label-cols-sm="0"
        class="p-0 m-0 mt-2"
      >
        <ModificationDate :Information="campus"></ModificationDate>
      </b-form-group>
      <div class="row">
        <div
          v-if="show_delete_button && !isNaN(this.campus.id)"
          class="col"
          style="text-align: left"
        >
          <b-button
            class="mr-1"
            size="sm"
            variant="danger"
            @click="deleteCampus"
            >Eliminar</b-button
          >
        </div>
        <div v-if="show_save_button" class="col" style="text-align: right">
          <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";
import "vue-multiselect/dist/vue-multiselect.min.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  name: "CampusForm",
  mixins: [validationMixin],
  components: {
    vSelect,
    ModificationDate: () => import("@/components/reusable/ModificationDate"),
  },
  props: {
    Campus: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          phone: "",
          address: null,
          updated_by: null,
          update_date: null,
        };
      },
    },
    Address: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          line_1: "",
          line_2: "",
          zip_code: "",
          commune: null,
        };
      },
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      campus: { ...this.Campus },
      address: { ...this.Address },
      selected_region: null,
      selected_country: null,
    };
  },
  validations: {
    campus: {
      name: {
        required,
        minLength: minLength(3),
      },
      address: {},
      phone: {},
    },
    address: {
      line_1: {
        required,
        minLength: minLength(3),
      },
      line_2: {},
      zip_code: {},
      commune: { required },
    },
    selected_region: { required },
    selected_country: { required },
    validationGroup: [
      "campus",
      "address",
      "selected_region",
      "selected_country",
    ],
  },
  computed: {
    ...mapGetters({
      regions: names.REGIONS,
      communes: names.COMMUNES,
      countries: names.COUNTRIES,
    }),
    filterRegions() {
      if (this.countries.length > 1) {
        if (this.selected_country) {
          return this.regions.filter((x) => x.country == this.selected_country);
        } else return [];
      } else {
        return this.regions;
      }
    },
    filterCommunes() {
      if (!this.selected_region) return this.communes;
      return this.communes.filter((x) => x.region == this.selected_region);
    },
  },
  methods: {
    dropdownShouldOpen(VueSelect) {
      if (this.filterCommunes.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    validateState(key) {
      const { $dirty, $error } = this.$v.campus[key];
      return $dirty ? !$error : null;
    },
    validateAddress(name) {
      const { $dirty, $error } = this.$v.address[name];
      return $dirty ? !$error : null;
    },
    validateCountry() {
      const { $dirty, $error } = this.$v.selected_country;
      return $dirty ? !$error : null;
    },
    validateRegion() {
      const { $dirty, $error } = this.$v.selected_region;
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.validationGroup.$touch();
      if (this.$v.validationGroup.$anyError) {
        return;
      }
      if (isNaN(this.address.id)) this.createAddress();
      else this.updateAddress();
    },
    createAddress() {
      this.$restful.Post(`/common/address/`, this.address).then((response) => {
        this.address = response;
        this.campus.address = response.id;
        this.$emit("created_address", response);
        if (isNaN(this.campus.id)) this.createCampus();
        else this.updateCampus();
      });
    },
    updateAddress() {
      this.$restful
        .Patch(`/common/address/${this.address.id}/`, this.address)
        .then((response) => {
          this.address = response;
          this.campus.address = response.id;
          this.$emit("updated_address", response);
          if (isNaN(this.campus.id)) this.createCampus();
          else this.updateCampus();
        });
    },
    createCampus() {
      this.$store.dispatch(names.POST_CAMPUS, this.campus).then((response) => {
        toast(
          String(
            this.$getVisibleNames("mesh.campus", false, "Sede") + " creada."
          )
        );
        this.$emit("created", response);
      });
    },
    updateCampus() {
      this.$store
        .dispatch(names.UPDATE_CAMPUS, this.campus)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames("mesh.campus", false, "Sede") +
                " actualizada."
            )
          );
          this.$emit("updated", response);
        });
    },
    deleteCampus() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.campus",
          false,
          "Sede"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_CAMPUS, this.campus.id);
          this.$emit("deleted", this.campus);
        }
      });
    },
  },
  mounted() {
    if (this.countries.length == 1)
      this.selected_country = this.countries[0].id;
  },
  created() {
    if (!isNaN(this.Campus.id) && this.Campus.address != null) {
      this.$restful
        .Get(`/common/address/${this.Campus.address}/`)
        .then((response) => {
          this.address = response;
          if (response.commune != null) {
            const commune = this.communes.find((x) => x.id == response.commune);
            if (commune) {
              this.selected_region = commune.region;
              const region = this.regions.find((x) => x.id == commune.region);
              if (region) this.selected_country = region.country;
            }
          }
        });
    }
  },
};
</script>

<style scoped>
.div-invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
</style>